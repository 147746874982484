import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-main-slides">
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/1.jpg"
          src="./images/13.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">Gimlovu Environmental Consult Ltd </h3>
          <p className="slide-p slide-p1">Save the Environment, Save Our Life</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/43.jpeg"
          src="./images/9.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p"> We do environmental and social monitoring of construction projects</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/53.jpeg"
            src="./images/5.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p3">
           Trust us for quality Environmental and Social Impact Assessment services
          </p>
        </Carousel.Caption>

      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/16.jpg"
          alt="Third slide"
        />

          <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
        We are prominent in Occupational Health and Safety services
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/18.jpeg"
          alt="Third slide"
        />

          <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
        We also provide HIV/AIDS mitigation measures services
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      
    </Carousel>
  );
}

export default CarouselFade;