import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Our Team | Gimlovu Environmental Consult Ltd';

},[]);

  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/9.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0',
    paddingTop:'12.5rem'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>Our Team</h2>
            
        </div>
    </section>
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           {/* <h2 className="home-h2 team-h2">Our Team</h2> */}
           <p className='home-p'>We have a policy of continuous professional development for all our personnel and our team is
continuously developing their professional expertise and knowledge. We also have a strong emphasis on in
house training and mentorship to facilitate the growth of individuals and to maximize their potential within
the company. Our human resource is immense, not just in numbers but also in expertise.</p>
       <p className='home-p'>The firm is staffed by a multi-disciplinary team of full time and part-time associate professionals. These
experts command a wealth of experience in their respective fields of specialization. The firm also maintains
close association with a few selected firms and certain individual consultants with whom it collaborates on a
project-to-project basis for enhancement of its capabilities.
</p>   
          <p className='home-p'>Below are our experts:
</p>
          

  <table className='team-table'>
     
    <tr>
      <th>No.</th>
      <th>Name and Title</th>
      <th>
Qualifications
</th>
      
    </tr>

     <tr>
      <td>1.</td>
      <td>Ms. Namatovu Faridah - 
Environmental, Social,
Health and Safety
Expert<br></br> 
<strong>  Expertise</strong>
      <ul>
          
          <li> Waste Management.</li> 
          <li>Occupational Health and Safety.</li> 
         <li>Air quality assessment and monitoring.</li> <li> Water resources management. </li><li> Soil science.</li>
         <li>NEMA Practitioner.</li>


      </ul></td>
      <td>
            <ul>
                  
      <li>Bachelor of Environment Science Technology and Management.</li>
      <li>Pursuing Master of Science in Conservation and
Natural Resources Management.</li>
      </ul>

          
      </td>
      {/* <td>
       <ul>
            <li>Civil works for the periodic maintenance of Kampala Northern Bypass Phase 1 (21 KM).</li>
          <li> Construction of selected road infrastructure in Kamuli Municipal Council, Lugazi Municipal Council and Jinja City under batch 2- USMID-AF PROJECT – MLHUD.</li> 
          <li>Upgrading of gravel/earth/surfaced roads to asphalt concrete ancillalies area in Kamuli Municipal Council including road ancillaries (industrial area road and Byaino road).</li> 
         <li>Upgrading of gravel/ earth/surfaced road to asphalt concrete paved roads in Lugazi Municipality (Kulubya road - 0.36km, Kinyoro road - 0.86km, Nabugabo close - 0.30km, Nabugabo road - 0.36km, market street 01 - 0.4km, market street 03 - 0.18km & market street 04 - 0.06km).</li> 
         <li> Embankment works on Nsango–Bulega swamp crossing between Bugiri and Butaleja districts (4.5km) located in Kisoko-Hasheba village Nsangaso parish, Buluguya sub county in Bugiri district. - under Bugiri District Local Government. </li>
         <li> Construction of lecture and laboratory block for Busitema University maritime institute, Namasagali campus, Kamuli district.</li>
      <li>The design and build of two ferry landing platforms and axillary facilities at Buvuma and Kiyindi --- under Ministry of Agriculture, Animal Industry and Fisheries (MAAIF), with support from the International Fund for Agricultural Development (IFAD).</li>
      <li>Construction of the drainage infrastructure in Masaka Municipality.</li>
      <li>The proposed fuel station in Dwaliro Village, Kasanda District.</li>
<li>The proposed fuel station in Nalututu Village, Kasanda District.</li>
 <li>Uganda Support to Municipal Infrastructure Development (USMID) program; funded by World Bank (WB) - Jinja main street, Busoga and Engineer Zikusooka roads in Jinja Municipality.</li>     
   <li>Para-transit/informal transport system & street usage in Greater Kampala Metropolitan Area (GKMA) in view of the planned Bus Rapid Transit (BRT) pilot project. _under Ministry of Works and Transport and KCCA obtained funding from African Development Bank.</li>   
    <li> Manufacturing factories established in Liao Shen industrial park Kepeeka (plot m06 channel lane Bugolobi, P. O. Box 22994, Kampala – Uganda).</li>
<li>Mining Projects for Kaolin and Clay for Hua Hui International Group Company Ltd Kapeeka.</li>
<li>Stone Quarry project located in Kapeka Sub county, Nakaseke District for Liao Shen industrial Park Plot M06 Channel Lane Bugolobi, P. O. Box 22994, Kampala – Uganda.</li>
 <li>Consultancy services for the feasibility study and detailed engineering design for Kampala Outer Beltway road project (KOB) under Uganda National Roads Authority (UNRA).</li> 
 <li>Consultancy services for the feasibility study and detailed engineering design for the VVIP express route (Nakasero – northern bypass expressway) under Uganda National Roads Authority (UNRA).</li>  
  <li>Upgrading of Kapchorwa _ Suam road funded by African Development Bank under Uganda National Roads Authority (UNRA).</li>   
  <li> The construction of a helicopter hangar and other associated facilities at the flying school (East African Civil Aviation Academy) in Soroti Municipality under Uganda People’s Defense Force (UPDF) received funds from the United States Embassy. </li> 
  <li>  Design and build for civil works for the construction of strategic bridges in northern-region under Uganda National Roads Authority (UNRA). </li> 
      </ul>     
      </td> */}
    </tr>

    <tr>
      <td>2.</td>
      <td> Nandagi
Drolence - Sociologist </td>
      <td>Bachelor’s Degree in
Social Science, Makerere
University - Uganda
</td>
      {/* <td>
<ul>
      <li>East African Crude Oil Pipeline (EACOP).</li>
      <li>Soroti – Katakwi – Akisim Road Upgrade to
Paved Standard.
</li>
<li> Northern Region Valley Tanks Project.</li>
</ul>
</td> */}
    </tr>


    <tr>
      <td>3.</td>
      <td>Mr. Gonza Daniel - Civil Engineer 
</td>
      <td></td>
      {/* <td>
            <ul>
                  <li>
                        Embankment works on Nsango–Bulega
swamp crossing between Bugiri and Butaleja
Districts (4.5km) located in Kisoko-Hasheba
village Nsangaso Parish, Buluguya Subcouty
in Bugiri District under Bugiri District
local government
                  </li>
<li>
      Construction of lecture and laboratory block
for Busitema University maritime institute,
Namasagali Campus, Kamuli District.
</li>
            </ul>
      </td> */}
    </tr>

    <tr>
      <td>4.</td>
      <td>Ahimbisibwe Herbert - Surveyor</td>
      <td>
         <ul>
           <li>Post Graduate Diploma
in Project Planning and
Management.</li> 
<li>Bachelor’s Degree in
Land Surveying.</li>
<li>Uganda Advanced
Certificate of Education (UACE).</li>
<li>Uganda Certificate of
Education (UCE).</li>

            </ul>
</td>
      {/* <td>
            <ul>
            <li> Consultancy contract for the Continuation of
Supervision of staged reconstruction of
Mukono-Jinja road(52KM).</li>
<li>Under supervision of Gauff Engineering
Consultants and contracted by Stirling Civil
Eng. ltd, Uganda.</li>
<li>Consultancy services for feasibility study and
detailed engineering design of selected
National roads, (204km) under UNRA.  </li>   
            </ul>
</td> */}
    </tr>
    <tr>
      <td>5.</td>
      <td>Kirabo Lovence - Environmental Health and Safety</td>
      <td>Bachelor of Environmental Science
Technology and Management</td>
{/* <td>
      <ul>
<li>Uganda Support to Municipal Infrastructure Development (USMID) program; funded by World Bank (WB) - Jinja main street, Busoga and Engineer Zikusooka roads in Jinja Municipality.</li>
<li>Upgrading of gravel/earth/surfaced road to asphalt concrete paved roads in Lugazi Municipality (Kulubya road - 0.36km, Kinyoro road - 0.86km, Nabugabo close - 0.30km, Nabugabo road - 0.36km, market street 01 - 0.4km, market street 03 - 0.18km & market street 04 - 0.06km).</li>
<li>Embankment works on Nsango–Bulega swamp crossing between Bugiri and Butaleja districts (4.5km) located in Kisoko-Hasheba village Nsangaso parish, Buluguya sub county in Bugiri district. - under Bugiri District Local Government.</li>  
<li>Construction of Kiyindi – Buvuma ferry landing site.</li>
<li>Rehabilitation of Bulucheke-Muchomu-Nyende-road (8.4km) in Bududa District-blasting of the rock outcrop.</li>
<li>Stone quarry project located in Kiyindi sub county, Buikwe district.</li>
<li>Construction of lecture and laboratory block for Busitema University maritime institute, Namasagali campus, Kamuli district.</li>
<li>Upgrading of gravel/earth/surfaced roads to asphalt concrete ancillaries’ area in Kamuli Municipal Council including road ancillaries (industrial area road 0.82km and Byaino road 0.8km).</li>
</ul>
</td> */}
   </tr>
   <tr>
      <td>6.</td>
      <td>Mr. Yusuf Saleh Saidi - Civil Engineer</td>
      <td></td>
{/* <td>
      <ul>
<li>Uganda Support to Municipal Infrastructure Development (USMID) program; funded by World Bank (WB) - Jinja main street, Busoga and Engineer Zikusooka roads in Jinja Municipality.</li>
<li>Upgrading of gravel/earth/surfaced road to asphalt concrete paved roads in Lugazi Municipality (Kulubya road - 0.36km, Kinyoro road - 0.86km, Nabugabo close - 0.30km, Nabugabo road - 0.36km, market street 01 - 0.4km, market street 03 - 0.18km & market street 04 - 0.06km).</li>
<li>Embankment works on Nsango–Bulega swamp crossing between Bugiri and Butaleja districts (4.5km) located in Kisoko-Hasheba village Nsangaso parish, Buluguya sub county in Bugiri district. - under Bugiri District Local Government.</li>  
<li>Construction of Kiyindi – Buvuma ferry landing site.</li>
<li>Rehabilitation of Bulucheke-Muchomu-Nyende-road (8.4km) in Bududa District-blasting of the rock outcrop.</li>
<li>Stone quarry project located in Kiyindi sub county, Buikwe district.</li>
<li>Construction of lecture and laboratory block for Busitema University maritime institute, Namasagali campus, Kamuli district.</li>
<li>Upgrading of gravel/earth/surfaced roads to asphalt concrete ancillaries’ area in Kamuli Municipal Council including road ancillaries (industrial area road 0.82km and Byaino road 0.8km).</li>
</ul>
</td> */}
   </tr>

  </table>



 


         </div>
    </section>
     
    </>
  )
}

export default Team