import Carousel from 'react-bootstrap/Carousel';

function WareHouse() {
  return (
    <Carousel  indicators={false} className="goals-carousel goals-carousel-a" >
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/34.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/35.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/36.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/37.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/38.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/39.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/40.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/41.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/42.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/43.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/44.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/45.jpeg"
          alt="Third slide"
        />
        
      </Carousel.Item>

    

    </Carousel>
  );
}

export default WareHouse;