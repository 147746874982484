import React,{useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaQuoteLeft, FaArrowAltCircleRight,FaCheckCircle} from 'react-icons/fa';
import {BsBarChartFill} from 'react-icons/bs'
import VisionCarousel from '../components/Vision Carousel';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

useEffect(()=>{
document.title = 'Gimlovu Environmental Consult Ltd | Save the Environment, Save Our Life';

},[]);


  return (
    <>
    <section>
      <CarouselFade></CarouselFade>
    </section>
        <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>Gimlovu Environmental Consult Ltd</strong> aims to provide an efficient, timely and affordable service to clients
whilst maintaining a high standard of environmental responsibility.
It has accumulated vast experience in environmental and social management, resettlement action plan,
integrated conservation, investment and development, and related fields. Managing, protecting and
restoring the environment are integral to our services.
</p>
<p className='home-p'>
The company strives in integrated planning, natural resource management, sustainable development and
information sharing, using integrated technology and tools such as ICT and GIS in our projects. We work in
support of ongoing feasibility studies, construction, maintenance and operations work for major
organizations and companies.
</p>


<p className='home-p'>
As a company, we are able to provide a direct and personalized service to our clients, and will strive to
maintain this rapport. We are able to provide fresh, innovative solutions which uphold environmental
sustainability, whilst satisfying the need for socioeconomic development.</p>

 
        </div>

    </section>
     {/* <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
          <div className='content-container vm-container'>
                 <div className='vision-mission-content vision-mission-content-a'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info vision-mission-info-a'>
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>
                            Our business brings advanced telemetric (GPS tracking combined with wireless communication) products to the consumer market. Our product allows consumers to track the location and manage their vehicle with their mobile phone/computers and be notified when unexpected events such as a hijacking, accident or break-in occur.</p>
                            <p>  Our product is positioned as the next generation car alarm - silent with features that are useful every day, not just when a theft is attempted. We also support personal tracking devices such as GPS enabled phones to further improve the "peace of mind" of our customers. We will also be adding support for other wireless networks to improve the coverage of our products.
</p>
                          
                          <h3 className='title-h3'>Advantages of our solutions</h3>
                          <div className='vm-line'></div>
                          <p>
                          Our system has been proven to be efficient and highly competitive. It assists our clients achieve the following goals:
</p>
<ul>
<li>Cut operational costs.</li>
<li>Increase revenue.</li>
<li>Monitor and control the movement of the vehicle.</li>
<li>Save lives.</li>
<li>Fuel control.</li>

</ul>


                    </article>

                    


                 </div>

          </div>
    </section> */}
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./images/9.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'4rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Why Choose Us?</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We believe that our clients' success is our success; and with professionalism, timely delivery, consistent
maintenance of high standards for service and participation of senior consultants to bear on every single
project gets us there.

</p>
<p>
Indefatigably working toward unremitting improvement, and broadening our potential, we have succeeded
in developing models and tools that serve to identify our clients specific requirements and cater to
addressing them in the most effective and efficient manner. We believe that this represents our main
competitive edge, and as such we strive to constantly upgrade and fine-tune these methodologies to
remain in line with local and regional business environments.
</p>
        </div>
    </section>

  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.663), rgba(0, 0, 0, 0.663)), url("./images/34.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We pledge to maintain the highest level of quality assurance and ensure unequalled customer satisfaction.

</p>
        </div>
    </section> */}
    
 {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'><strong> REJO ENGINEERING LIMITED</strong> began as a general works contractor in 2014. Over the
years, the company has undertaken many challenging projects and accumulated skills, know-how and
experiences in delivering building projects, water projects, road and bridge construction
projects and build solutions, project management services, building trades and related
engineering works.
<br></br> <br></br>
Today, <strong>REJO ENGINEERING LIMITED</strong> takes on the role of main contractor for small,
medium and large size construction projects and performs project management services to
coordinate specialist trades for industrial/commercial projects. We also provide design inputs
and engineering solutions as value-add services to our clients.
<br></br> <br></br>
We take pride in our delivery, thus our clients can always be assured that only the most experienced and qualified people are serving them, all the time.</p>
                     </div>


                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>

       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2>
     <h2 className='home-h2 home-service-h2'>Our Services</h2>
    <div className='line1'> 
    <div className='line2'></div>
    </div>
    
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Vehicle Tracking Services</h3>
          <PowerCarousel></PowerCarousel>
          <img src='./images/6a.jpg' className='home-service-image'></img>
          <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p>
            <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link>

        </div>
        
       
      
        
         
         
   


    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>
<Link to='features' className='home-btn' onClick={scrollFunc}>Key Features</Link>

        </div>

  </section> */}
 {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.863)), url("./images/4.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Objectives</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'> To provide the most efficient installation, repair
and maintenance solutions for clients’
dependable work continuity.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To ensure timely, correct and best quality
delivery of services and supplies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To grow and maintain trusted relationships
with our clients, partners and government.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To foster client up-to-date market awareness.

</p>
                     </div>
                    
                </div>
                   

           </article>

           




       </div>

  </section> */}

{/*   
   <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/11.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Clientele</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list-a'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Individuals. 
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Tour & Travel Operators.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Transportation & Haulage Companies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'> Taxi Operators. </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Delivery & Distribution Companies. </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Leasing & Car Rental Companies.</p>
                     </div>

                </div>
                   

           </article>

       </div>
                    
  </section> */}

  {/* <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
           <div className='line1'> 
           <div className='line2'></div>
           </div>
          <p className='clients-para'></p>
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/1.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/2.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/3.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/4.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/5.png' alt='client' className='client-image'></img>
           </article>

          </div> 
    </section> */}
   
    
  
    </>
  )
}

export default Home